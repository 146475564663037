import { ref, watch } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { formatCurrency, formatDateTimeDDMMYYYY } from '@core/utils/utils'

export default function useWorkplaceList() {
  // Use toast
  const toast = useToast()

  const refWorkplaceListTable = ref(null)
  const launchMode = {
    imediato: 'IMEDIATO',
    integration: 'Integração',
    imediato_integration: 'IMEDIATO/Integração',
  }

  const launchModeVariant = {
    imediato: 'primary',
    integration: 'secondary',
    imediato_integration: 'info',
  }

  // Table Handlers
  const tableColumns = [
    {
      key: 'name',
      sortable: false,
      label: 'Unidade',
    },
    {
      key: 'payday',
      sortable: false,
      label: 'Dia de pagamento',
      formatter: value => (value || '-'),
    },
    {
      key: 'payment_month',
      sortable: false,
      label: 'Mês de pagamento',
      formatter: value => (value || '-'),
    },
    {
      key: 'blocked_at',
      sortable: false,
      label: 'Bloqueio Cliente',
    },
    {
      key: 'request_enabled_at',
      sortable: false,
      label: 'Plantão Liberado',
    },
    {
      key: 'person_type',
      sortable: false,
      label: 'Tipo Plantão',
    },
    {
      key: 'physician_duty_launch_mode',
      sortable: false,
      label: 'Tipo Lançamento',
      // formatter: value => launchMode[value],
    },
    {
      key: 'payroll_value',
      sortable: false,
      label: 'Valor Folha',
      formatter: value => formatCurrency(value),
    },
    {
      key: 'contract_effective_date',
      sortable: false,
      label: 'Vigência Contrato',
      formatter: value => (value ? formatDateTimeDDMMYYYY(value) : '-'),
    },
    {
      key: 'can_advance',
      sortable: false,
      label: 'Pode Adiantar?',
      formatter: value => (value ? 'Sim' : 'Não'),
    },
    { key: 'actions', sortable: false, label: 'Ações' },
  ]
  const institutionFilter = ref(null)

  const refetchData = () => {
    refWorkplaceListTable.value.refresh()
  }

  watch([institutionFilter], () => {
    refetchData()
  })

  const fetchWorkplaces = (ctx, callback) => {
    store
      .dispatch('app-institution/fetchWorkplaceByInstitution', {
        institution_id: institutionFilter.value,
      })
      .then(response => {
        callback(response.data.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao buscar',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const getLaunchMode = value => ({
    name: launchMode[value],
    variant: launchModeVariant[value],
  })

  return {
    getLaunchMode,
    fetchWorkplaces,
    tableColumns,
    refetchData,
    refWorkplaceListTable,
    institutionFilter,
  }
}
